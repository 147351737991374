<template>
  <div>
    <v-row class="actions-bar pa-4 bar-shadow primary-background">
      <!-- Filters for location, year, etc. -->
      <v-col />
      <v-col class="actions">
        <Button
          id="add-holiday-button"
          color="primary"
          @clickAction="isDialogOpened = true"
        >
          {{ addHolidayText }}
        </Button>
      </v-col>
    </v-row>
    <v-row class="pa-4">
      <v-col>
        <v-card>
          <HolidaysTable @save="getHolidays" />
        </v-card>
      </v-col>
    </v-row>

    <AddHolidayDialog
      :is-opened="isDialogOpened"
      @close="isDialogOpened = false"
      @save="getHolidays"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Button } from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';

import AddHolidayDialog from './AddHolidayDialog.vue';
import HolidaysTable from './HolidaysTable.vue';

export default {
  components: {
    AddHolidayDialog,
    Button,
    HolidaysTable,
  },
  data() {
    return {
      isDialogOpened: false,
      addHolidayText: 'Add Holiday',
      loadingTypes,
    };
  },
  computed: {
    ...mapGetters('holidays/list', ['holidays']),
  },
  created() {
    this.getHolidays();
  },
  methods: {
    ...mapActions('holidays/list', ['getHolidays']),
  },
};
</script>
<style lang="less">
@import "~@/styles/actions-bar";
</style>
