<template>
  <div>
    <v-data-table
      id="holidays-data-table"
      no-data-text="No data available"
      disable-pagination
      hide-default-footer
      fixed-header
      :headers="headers"
      :items="holidays"
      item-key="id"
      :dense="denseTables"
      :height="defaultTableHeight"
      :sort-by="['date']"
    >
      <template #item="{ isSelected, select, item }">
        <tr
          :active="isSelected"
          active-class="highlighted"
        >
          <td
            v-for="(header, index) in headers.filter(h => !h.hidden)"
            :key="index"
          >
            <template v-if="header.value === 'date'">
              {{ dateTimeFormat(item['date']) }}
            </template>
            <template v-else-if="header.value === 'description'">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span
                    class="cell-content-with-ellipsis"
                    v-on="on"
                  >
                    <span>
                      {{ item[header.value] }}
                    </span>
                  </span>
                </template>
                <span>
                  {{ item[header.value] }}
                </span>
              </v-tooltip>
            </template>
          </td>
          <td class="action-column">
            <template>
              <v-row>
                <v-btn
                  text
                  icon
                  small
                  @click.stop="editHoliday(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  text
                  icon
                  small
                  @click.stop="deleteHoliday(item)"
                >
                  <v-icon>delete_outline</v-icon>
                </v-btn>
              </v-row>
            </template>
          </td>
        </tr>
      </template>
      <template #footer>
        <v-divider />
        <v-row class="table-footer">
          <span class="footer-text">Total records: {{ holidays.length }}</span>
        </v-row>
      </template>
    </v-data-table>

    <DeletionConfirmationDialog
      :dialog="isDeleteConfirmationDialogOpened"
      title="Confirmation"
      text="Are you sure you want to delete this holiday?"
      confirmation-button-text="Delete"
      @confirmationAction="holidayDeleteConfirmation"
    />

    <EditHolidayDialog
      :is-opened="isEditDialogOpened"
      :holiday-item="itemToEdit"
      @close="isEditDialogOpened = false"
      @save="$emit('save')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { Dialog as DeletionConfirmationDialog } from '@/components/shared';
import { defaultTableHeight } from '@/constants/tableHeights';
import { DateHelper } from '@/helpers/date.helper';
import apiClient from '@/services/api-client';

import EditHolidayDialog from './EditHolidayDialog.vue';

export default {
  components: {
    DeletionConfirmationDialog,
    EditHolidayDialog,
  },
  data: () => ({
    defaultTableHeight,
    headers: [
      {
        text: 'Date',
        value: 'date',
        class: ['holidays-headers'],
        width: '200px',
      },
      {
        text: 'Description',
        value: 'description',
        class: ['holidays-headers'],
      },
      /* TBD
             {
              text: 'Location',
              value: 'location_id',
              class: ['holidays-headers'],
              width: '120px',
            }, */
      {
        text: 'Actions',
        hidden: true,
        class: ['holidays-headers'],
        width: '100px',
      },
    ],
    isDeleteConfirmationDialogOpened: false,
    isEditDialogOpened: false,
    itemToDelete: {},
    itemToEdit: {},
  }),
  computed: {
    ...mapGetters('user/settings', [
      'denseTables',
      'dateFormatSettings',
    ]),
    ...mapGetters('holidays/list', ['holidays']),
  },
  methods: {
    deleteHoliday(item) {
      this.itemToDelete = item;
      this.isDeleteConfirmationDialogOpened = true;
    },
    editHoliday(item) {
      this.itemToEdit = item;
      this.isEditDialogOpened = true;
    },
    async holidayDeleteConfirmation(confirmed) {
      if (confirmed) {
        try {
          await apiClient.holidayApi.deleteHoliday(this.itemToDelete.id);
          this.$emit('save');
        } catch (error) {
          this.flashError('An error occured while deleting holiday.');
        }
      }

      this.isDeleteConfirmationDialogOpened = false;
    },
    dateTimeFormat(dateTime) {
      const localTime = DateHelper.utcToLocal(dateTime);

      return DateHelper.formatDateAccordingSettings(localTime, this.dateFormatSettings);
    },

  },
};
</script>

<style lang="less">
@import "~variables";

#holidays-data-table .holidays-headers {
  font-size: @table-font-size;
}
</style>
