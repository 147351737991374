<template>
  <Dialog
    is-persistent
    :title="dialogTitle"
    :dialog="isOpened"
    :confirmation-button-text="confirmationButtonText"
    @confirmationAction="updateHoliday"
  >
    <template #content>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row>
          <v-col class="pb-4">
            <DatePicker
              id="holiday-date-input"
              ref="date"
              v-model="holidayItem.date"
              :rules="dateRules"
              label="Date"
              required
            />
          </v-col>
        </v-row>
        <v-row class="pb-4">
          <v-col>
            <v-text-field
              id="holiday-description-input"
              v-model="holidayItem.description"
              class="secondary-background"
              label="Description"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </Dialog>
</template>

<script>
import { Dialog, DatePicker } from '@/components/shared';
import apiClient from '@/services/api-client';

export default {
  components: {
    Dialog,
    DatePicker,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    holidayItem: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      dialogTitle: 'Edit Holiday',
      confirmationButtonText: 'Save',
      valid: true,
      dateRules: [(value) => !!value || 'Date is required'],
    };
  },
  computed: {
    form() {
      return { ...this.holidayItem };
    },
  },
  async created() {
    document.addEventListener('keydown', this.handleKeypress);
    document.addEventListener('keyup', this.handleKeyup);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    async updateHoliday(confirmed) {
      if (!confirmed) {
        this.closeDialog();

        return;
      }

      if (!this.validate()) {
        return;
      }

      try {
        await apiClient.holidayApi.updateHoliday(
          this.holidayItem.id,
          this.holidayItem
        );
        this.$emit('save');
      } catch (error) {
        this.flashError('An error occured while saving holiday.');
      } finally {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit('close');
      this.reset();
    },
    reset() {
      this.resetValidation();
    },
    validate() {
      return this.$refs.form.validate();
    },
    handleKeypress(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.closeDialog();
      }
    },
    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
        this.addHoliday(true);
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
